import './menu.css';
import {useEffect, useState} from "react";

export default function MenuAdministracion() {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <>
                <header>
                    <h1>Oposicions Galicia</h1>
                </header>
            </>
            <div className="sidebar">
                <a onClick={toggleMenu} id="menu-toggle">☰</a>
                <ul id="menu" className={isMenuOpen ? 'visible' : 'hidden'}>
                    <h2>Menú Administracion</h2>
                    <li><a href="/public/admin">Inicio</a></li>
                    <li><a href="/public/admin/bop">Administrar BOPs</a></li>
                    <li><a href="/public/admin/opos">Administrar Opos</a></li>
                    <li><a href="/public/admin/verProceso/opo">Ver procesos Oposicion</a></li>
                    <li><a href="/public/admin/crearProceso/opo">Crear proceso Oposicion</a></li>
                    <li><a href="/public/admin/etiquetas">Administrar Etiquetas</a></li>
                    <li><a href="/public/admin/configurarBusqueda">Administrar Busqueda</a></li>
                    <li><a href="/public/admin/notificaciones">Administrar Notificaciones</a></li>
                    <li><a href="/public/admin/estadisticas">Administrar Estadisticas</a></li>
                    <li><a href="/public/admin/cerrarSesion">Sair da sesion</a></li>
                </ul>
            </div>
        </>
    );
}