import React, {useEffect, useState} from "react";
import Footer from "../../../component/footer/footer";
import {SESION_REDIRECT_SESION, URL_ADMIN_HOME_LOGIN} from "../../../core/constantes";
import {useNavigate} from "react-router-dom";
import {callApiAdministrarRelanzarConfig} from "../../../core/restUtils";
import MenuAdministracion from "../../../component/menu/administrador/menu";

export default function AdminHome() {

    const [data, setData] = useState();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);

        // Comprobar datos redirect en sesion
        if (localStorage.getItem(SESION_REDIRECT_SESION) != null) {
            navigate(localStorage.getItem(SESION_REDIRECT_SESION));
        }
        setLoading(false);
    }, []);

    const handleInputDateChange = (event) => {
        setData(event.target.value);
    };

    const relanzarConfigByDate = (event) => {
        setLoading(true);
        event.preventDefault();
        relanzarBusquedaPorFecha();
    };

    const relanzarBusquedaPorFecha = async () => {
        try {
            setLoading(false);
            await callApiAdministrarRelanzarConfig(data, true)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const renderHome = () => {

        if (loading) return <div className="carga"><p><img key="carga" alt="carga" src="/public/loading.gif" height="100" width="100"/></p></div>;
        if (error) return navigate(URL_ADMIN_HOME_LOGIN);

        return (
            <>
                <div className="content">
                    <h1>Benvido</h1>
                    <p>Esta é a página principal.</p>
                    <div>
                        <h1>Relanzar configuración fecha</h1>
                        <div className="buscador" key="containerBuscadorPorFecha">
                            <label htmlFor="search-date">Relanzar por fecha:</label>
                            <input type="date" key="search-date" name="search-date" value={data}
                                   onChange={handleInputDateChange}/>
                            <button onClick={relanzarConfigByDate}>Relanzar</button>
                        </div>
                    </div>
                </div>
            </>
        );

    }

    return (
        <>
            {<MenuAdministracion/>}
            {renderHome()}
            {<Footer/>}
        </>
    );

}
