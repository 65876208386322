import React, {useEffect, useState} from "react";
import Footer from "../../../component/footer/footer";
import {callApiMaestrosVerEtiquetas} from "../../../core/restUtils";
import "./etiquetas.css";
import MenuAdministracion from "../../../component/menu/administrador/menu";

export default function AdministrarEtiquetasAdmin() {

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        getEtiquetasConfiguracion();
    }, []);

    const getEtiquetasConfiguracion = async () => {
        try {
            setLoading(true);
            const result = await callApiMaestrosVerEtiquetas()
            setPosts(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };


    const renderConfigEtiquetas = () => {

        if (loading) return <div className="carga"><p><img key="carga" alt="carga" src="/public/loading.gif" height="100" width="100"/></p></div>;
        if (error) return <div className="carga"><p>Error: {error.message}</p></div>;

        return posts.map((item, index) => (
            <>
                <ul id={index}>
                    <div className="busqueda" key={item.uuidEntidad}>
                        <h3>Etiqueta: {item.etiqueta}</h3>
                        <h3>Texto Busqueda: {item.textoBusqueda}</h3>
                        <h3>Prioridad: {item.prioridad}</h3>
                        <p>UUID: {item.uuidEntidad}</p>
                    </div>
                </ul>
            </>
        ));

    }

    return (
        <>
            {<MenuAdministracion/>}
            <div className="content">
                {renderConfigEtiquetas()}
            </div>
            {<Footer/>}
        </>
    );

}