import React, {useEffect, useState} from "react";
import {callApiOposBuscarOposicionDetalleUrlAmigable} from "../../core/restUtils";
import {formatDate} from "../../core/utiles";
import Menu from "../../component/menu/menu";
import Footer from "../../component/footer/footer";
import {useParams} from "react-router-dom";
import './oposicion.css';

export default function Oposicion() {

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { opo } = useParams();

    useEffect(() => {
        setLoading(true);
        getDataOposicionDetallePorUrl(opo);
    }, []);

    const getDataOposicionDetallePorUrl = async (urlAmigable) => {
        try {
            const result = await callApiOposBuscarOposicionDetalleUrlAmigable(urlAmigable)
            setPosts(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const renderOposicionDetalle = () => {

        if (loading) return <div className="carga"><p><img key="carga" alt="carga" src="/public/loading.gif" height="100" width="100"/></p></div>;
        if (error) return <div className="carga"><p>Error: {error.message}</p></div>;

        // Formatear a data
        const fechaFormateada = formatDate(posts.fechaBopInicioProceso[2], posts.fechaBopInicioProceso[1], posts.fechaBopInicioProceso[0])

        return (
            <>
                <img key="carga" alt="carga" src={posts.urlImagen} height="200px" width="auto"/>
                <h1>{posts.titulo}</h1>

                <div id="contenedorDatos">
                    <p>{posts.titulo}</p>
                    <h4>Estado oferta: {posts.procesoFinalizado ? 'Pechado' : 'Aberto'}</h4>
                    <h4>Sistema de selección: {posts.sistemaSeleccion}</h4>
                    <h4>Tipo: {posts.tipoContrato}</h4>
                    <h4>Boletín Oficial publicación: {posts.bop}</h4>
                    <h4>Data publicación en Boletín Oficial : {fechaFormateada}</h4>

                </div>

                {posts.procesosOposicionesList.map(oposiAux => (
                        <div className="listaEdictos">
                            <a href={oposiAux.urlDetalle} ><h5>{oposiAux.titulo}</h5></a>
                            <h5>Fecha BOP: {oposiAux.fechaBop}</h5>
                        </div>
                    )
                )}

            </>
        );
    };

    return (
        <>
            {<Menu/>}
            <div id="oposicionDetalleId" className="oposicionDetalle">
                {renderOposicionDetalle()}
            </div>
            {<Footer/>}
        </>
    );
}