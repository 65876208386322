import React, {useEffect, useState} from "react";
import Footer from "../../../component/footer/footer";
import MenuAdministracion from "../../../component/menu/administrador/menu";
import {formatDate} from "../../../core/utiles";
import {callApiAdministrarEliminarOpo, callApiAdministrarModificarOpo, callApiOposConsultaPorUuidOpoAdmin} from "../../../core/restUtils";
import {useNavigate} from "react-router-dom";
import {URL_ADMIN_OPOS} from "../../../core/constantes";
import "./modificarOpo.css";

export default function AdministrarModificarOposAdmin() {

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [values, setValues] = React.useState({
        organizacion: "",
        descripcion: "",
        urlDetalleBopExterno: "",
        uuidProcesoSelectivo: "",
        procesoMostrable: "true"
    });

    useEffect(() => {
        setLoading(true);
        buscarDatosOpo();
    }, []);

    const buscarDatosOpo = () => {

        // recuperamos el querystring
        const querystring = window.location.search
        console.log(querystring)

        // usando el querystring, creamos un objeto del tipo URLSearchParams
        const params = new URLSearchParams(querystring)
        console.log(params)

        if (params == null || params.get("opoUuid") == null) {
            navigate(URL_ADMIN_OPOS);
        } else if (params.get("opoUuid") != null) {
            console.log("Buscado ops por uuid")
            getDataOposPorUuid(params.get("opoUuid"));
        }

    }

    const eliminarOpo = async (uuidOpo) => {
        try {
            setLoading(true);
            await callApiAdministrarEliminarOpo(uuidOpo);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const modificarOpo = async (uuidOpo, org, descripcion, url, uuidProcesoSelectivo, verificado) => {
        try {
            setLoading(true);
            await callApiAdministrarModificarOpo(uuidOpo, org, descripcion, url, uuidProcesoSelectivo, verificado);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const getDataOposPorUuid = async (uuid) => {
        try {
            setLoading(true);
            const result = await callApiOposConsultaPorUuidOpoAdmin(uuid)
            if (result != null) {
                // Asignar valores
                setValues({
                    organizacion: result[0].organizacion,
                    descripcion: result[0].descripcion,
                    urlDetalleBopExterno: result[0].urlDetalle,
                    procesoMostrable: result[0].verificado
                });
            }
            setPosts(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    function handleChange(evt) {
        /*
          evt.target es el elemento que ejecuto el evento
          name identifica el input y value describe el valor actual
        */
        const { target } = evt;
        const { name, value } = target;

        /*
          Este snippet:
          1. Clona el estado actual
          2. Reemplaza solo el valor del
             input que ejecutó el evento
        */
        const newValues = {
            ...values,
            [name]: value,
        };

        // Sincroniza el estado de nuevo
        setValues(newValues);
    }

    function handleSubmit(evt) {
        /*
          Previene el comportamiento default de los
          formularios el cual recarga el sitio
        */
        evt.preventDefault();

        // Consultar uuid
        const buttonUuidValue = evt.target.querySelector('button[type="submit"]').value;

        modificarOpo(buttonUuidValue, values.organizacion, values.descripcion, values.urlDetalleBopExterno, values.uuidProcesoSelectivo, values.procesoMostrable);

        alert("Opo modificada correctamente")

        setError(null)
        setLoading(true);
        buscarDatosOpo();

    }

    function handleEliminarSubmit(evt) {
        /*
          Previene el comportamiento default de los
          formularios el cual recarga el sitio
        */
        evt.preventDefault();

        // Consultar uuid
        const buttonValue = evt.target.querySelector('button[type="submit"]').value;

        eliminarOpo(buttonValue);

        alert("Opo borrada correctamente")

        navigate(URL_ADMIN_OPOS);

    }

    const renderOpos = () => {

        if (loading) return <div className="carga"><p><img key="carga" alt="carga" src="/public/loading.gif" height="100" width="100"/></p></div>;
        if (error) return <div className="carga"><p>Error: {error.message}</p></div>;

        return posts.map((item, index) => {

            // Formatear a data
            const fechaFormateada = formatDate(item.fechaBop[2], item.fechaBop[1], item.fechaBop[0])

            return (
                <>
                    <form onSubmit={handleSubmit}>
                        <h2>Modificar OPO</h2>
                        <div className="modificarOpo" key="modificarOpoId">

                            <h3>BOP: {item.bop}</h3>
                            <h3>Fecha BOP: {fechaFormateada}</h3>
                            <h3>UUID: {item.uuid}</h3>

                            <label htmlFor="organizacionId">Organización:</label>
                            <input type="text" name="organizacion" id="organizacionId" value={values.organizacion}
                                   onChange={handleChange}/>

                            <label htmlFor="descripcionId">Descripcion:</label>
                            <input type="text" name="descripcion" id="descripcionId" value={values.descripcion}
                                   onChange={handleChange}/>

                            <label htmlFor="uuidProcesoSelectivoId">UUID Proceso Opo:</label>
                            <input type="text" name="uuidProcesoSelectivo" id="uuidProcesoSelectivoId"
                                   onChange={handleChange}/>

                            <label htmlFor="urlDetalleBopExternoId">Url detalle Bop:</label>
                            <input type="text" name="urlDetalleBopExterno" id="urlDetalleBopExternoId"
                                   value={values.urlDetalleBopExterno}
                                   onChange={handleChange}/>

                            <label htmlFor="procesoMostrableId">Indexable:</label>
                            <select name="procesoMostrable" id="procesoMostrableId" onChange={handleChange} value={values.procesoMostrable}>
                                <option value="true">SI</option>
                                <option value="false">NON</option>
                            </select>

                            <button type="submit" value={item.uuid}>Modificar</button>
                        </div>
                    </form>
                    <form onSubmit={handleEliminarSubmit}>
                        <button type="submit" value={item.uuid}>Eliminar</button>
                    </form>
                </>
            )
        });
    };

    return (
        <>
            {<MenuAdministracion/>}
            <div className="content">
                <h1>Administrar Opo</h1>
                {renderOpos()}
            </div>
            {<Footer/>}
        </>
    );

}