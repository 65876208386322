import React, {useEffect, useState} from "react";
import Footer from "../../../component/footer/footer";
import "./bop.css";
import MenuAdministracion from "../../../component/menu/administrador/menu";
import {callApiAdministrarEliminarBop, callApiOposConsultarBops} from "../../../core/restUtils";
import {formatDateConGuion} from "../../../core/utiles";
import {URL_ADMIN_OPOS_SEARCH_DATE} from "../../../core/constantes";
import {useNavigate} from "react-router-dom";

export default function AdministrarBopAdmin() {

    const navigate = useNavigate();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        getDataBop();
    }, []);

    const getDataBop = async () => {
        try {
            setLoading(true);
            const result = await callApiOposConsultarBops()
            setPosts(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const eliminarBop = async (uuidBop) => {
        try {
            setLoading(true);
            await callApiAdministrarEliminarBop(uuidBop);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    function handleVerInfoSubmit(evt) {
        /*
          Previene el comportamiento default de los
          formularios el cual recarga el sitio
        */
        evt.preventDefault();

        // Consultar fecha
        const buttonValue = evt.target.querySelector('button[type="submit"]').value;
        const imputValueNomeBop = evt.target.querySelector('input[name="nomeBop"]').value;

        const urlRedirect = URL_ADMIN_OPOS_SEARCH_DATE + buttonValue + "&nomeBop=" + imputValueNomeBop;

        navigate(urlRedirect);

    }

    function handleEliminarSubmit(evt) {
        /*
          Previene el comportamiento default de los
          formularios el cual recarga el sitio
        */
        evt.preventDefault();

        // Consultar uuid
        const buttonValue = evt.target.querySelector('button[type="submit"]').value;

        eliminarBop(buttonValue);

        // Recargamos bops
        getDataBop();

    }

    const renderBops = () => {

        if (loading) return <div className="carga"><p><img key="carga" alt="carga" src="/public/loading.gif" height="100" width="100"/></p></div>;
        if (error) return <div className="carga"><p>Error: {error.message}</p></div>;

        return posts.map((item, index) => {

            // Formatear a data
            const fechaFormateada = formatDateConGuion(item.fechaBop[2], item.fechaBop[1], item.fechaBop[0])

            return (
                <>
                    <div className="bopAdmin" key={index}>
                        <h2>{item.bop} ({fechaFormateada})</h2>
                        <h3>UUID: {item.uuid}</h3>
                        <form onSubmit={handleVerInfoSubmit}>
                            <input name="nomeBop" type="hidden" value={item.bop}/>
                            <button type="submit" value={fechaFormateada}>Ver opos</button>
                        </form>
                        <form onSubmit={handleEliminarSubmit}>
                            <button type="submit" value={item.uuid}>Eliminar</button>
                        </form>
                    </div>
                </>
            )
        });
    };


    return (
        <>
            {<MenuAdministracion/>}
            <div className="content">
                <h1>Listado de Bops</h1>
                {renderBops()}
            </div>
            {<Footer/>}
        </>
    );

}